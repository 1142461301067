import React from 'react'
import Layout from '../components/layouts/page'
import { Helmet } from 'react-helmet'

const Reservation = () => {
	return (
		<div className="page">
    <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Reserve a Limo in Southern California</title>
        <script type="text/javascript" src="https://book.mylimobiz.com/v4/widgets/widget-loader.js"></script>
    </Helmet>
		<Layout>
    <section className='bg-light'>
        <div className='container-xxl'>
          <div className='d-flex justify-content-center'>
            <div className='col-md-8 text-center py-5'>
              <div className='py-5'>
                <h1 className='display-1 fw-medium mb-3'>Make A Reservation</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container-xxl'>
          <div className='d-flex justify-content-center'>
            <div className='col-md-8 py-5'>
              <a href="https://book.mylimobiz.com/v4/devineride" data-ores-widget="website" data-ores-alias="devineride">Online Reservations</a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
    </div>
  )
}

export default Reservation